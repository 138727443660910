import React from "react";
import style from "./firstBanner.module.scss";

const FirstBanner = ({ header, description, banner }) => {
  return (
    <div className={style.mainWrapper}>
      <div className={style.innerWrapper}>
        <h1>{header}</h1>
        <p>{description}</p>
      </div>
      <div
        className={style.innerWrapper}
        style={{
          backgroundImage: `url(${banner})`,
        }}
      ></div>
    </div>
  );
};

export default FirstBanner;
