/* eslint-disable react-hooks/exhaustive-deps */
import style from "./grid.module.scss";

import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Card from "../card/Card";
import { MainContext } from "../../../store/main-context";
import Loader from "../../Shared/Loader/Loader";

const Grid = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const mainCtx = useContext(MainContext);

  useEffect(() => {
    console.log("without page");

    mainCtx.getProjectsWithPagination(1);
  }, []);

  useEffect(() => {
    if (!mainCtx.isEmptyObject(mainCtx.projects)) {
      setTotalPages(mainCtx.projects.data.meta.pagination.pageCount);
      setPage(mainCtx.projects.data.meta.pagination.page);
    }
  }, [mainCtx.projects]);

  useEffect(() => {
    console.log("with page");
    mainCtx.getProjectsWithPagination(page);
  }, [page]);

  return (
    <div className={style.wrapper}>
      <div className={style.grid}>
        {mainCtx.isEmptyObject(mainCtx.projects) ? (
          <Loader />
        ) : (
          mainCtx.projects.data.data.map((project, key) => {
            return (
              <Card
                id={123}
                key={key + project.attributes.Title}
                address={project.attributes.Address}
                image={project.attributes.Photo.data.attributes.url}
                link={`/projects/${project.id}`}
              />
            );
          })
        )}
      </div>
      <div className={style.paginationWrapper}>
        <ReactPaginate
          containerClassName={style.pagination}
          pageClassName={style.pageItem}
          activeClassName={style.active}
          onPageChange={(event) => {
            setPage(event.selected + 1);
          }}
          pageCount={totalPages}
          breakLabel="..."
          previousLabel={false}
          nextLabel={false}
        />
      </div>
    </div>
  );
};

export default Grid;
