import style from "./fourthBanner.module.scss";

const FourthBanner = ({ image, blocks }) => {
  return (
    <div className={style.wrapper}>
      <div
        className={style.bgImage}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className={style.col}>
        <h1>{blocks[0].Title}</h1>
        <p>{blocks[0].Description}</p>
        <div className={style.grid}>
          {blocks.slice(1, 5).map((block) => {
            return (
              <div className={style.gridCol} key={block.Title}>
                <h3>{block.Title}</h3>
                <p>{block.Description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FourthBanner;
