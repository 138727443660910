import React from "react";
import style from "./card.module.scss";
import pin from "../../../assets/icons/pin.png";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../store/main-context";

const Card = ({ link, image, address }) => {
  return (
    <Link to={link} className={style.card}>
      <div
        className={style.bgImage}
        style={{
          backgroundImage: `url(${BASE_URL + image})`,
        }}
      />
      <div className={style.projectInfo}>
        <div className={style.location}>
          <img src={pin} alt="Varli Construction Project Location" />
          <p>{address}</p>
        </div>
        <p>See more...</p>
      </div>
    </Link>
  );
};

export default Card;
