import style from "./navbar.module.scss";

import logo from "../../../assets/fullLogo.png";
import logoFooter from "../../../assets/logoFooter.svg";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import arrowRightWhite from "../../../assets/icons/arrowRightWhite.svg";
import { Squash as Hamburger } from "hamburger-react";

import { Link } from "react-router-dom";
import { useState } from "react";

function Navbar() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(false);
  };
  return (
    <div className={style.navbar}>
      <div className={style.wrapper}>
        <Link to="/">
          <img src={logo} alt="Varli Contruction" />
        </Link>
        <div className={style.links}>
          <Link to={"/projects"}>Projects</Link>
          <Link to={"about"}>About</Link>
          {/* <Link to={"blog"}>Blog</Link> */}
        </div>
        <div className={style.links}>
          <Link to={"contact-us"}>
            Contact Us <img src={arrowRight} alt="Contact Us" />
          </Link>
        </div>

        <div className={style.hamburger}>
          <Hamburger toggled={open} toggle={setOpen} color="#0d0d0d" />
        </div>
      </div>
      {open ? (
        <div
          className={`${style.mobileNav} animate__animated animate__fadeInDown`}
        >
          <div className={style.hamburgerMobile}>
            <Hamburger toggled={open} toggle={setOpen} color="#fdfdfd" />
          </div>
          <Link to={"/"}>
            <img src={logoFooter} alt="Varli Contruction" />
          </Link>
          <div className={style.links}>
            <Link to={"/projects"} onClick={handleOpen}>
              Projects
            </Link>
            <Link to={"about"} onClick={handleOpen}>
              About
            </Link>
            {/* <Link to={"blog"} onClick={handleOpen}>
              Blog
            </Link> */}
            <Link to={"contact-us"} onClick={handleOpen}>
              Contact Us
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Navbar;
