/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import style from "./projects.module.scss";
import Grid from "../../components/projects/Grid/Grid";
import withHelmet from "../../seo/withHelmet";
import { projectPageHelmetProps } from "../../seo/helmetProps";
import { MainContext } from "../../store/main-context";
import Loader from "../../components/Shared/Loader/Loader";

const Projects = () => {
  const mainCtx = useContext(MainContext);

  useEffect(() => {
    mainCtx.getProjectsPage();
  }, []);

  return (
    <>
      {mainCtx.isEmptyObject(mainCtx.projectsPage) ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <h1>{mainCtx.projectsPage.data.data.attributes.Title}</h1>
          <Grid />
        </div>
      )}
    </>
  );
};

export default withHelmet(Projects, projectPageHelmetProps);
