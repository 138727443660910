import style from "./sixthBanner.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useRef } from "react";
import { BASE_URL } from "../../../store/main-context";

const SixthBanner = ({ title, desc, reviews }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  console.log(reviews);

  return (
    <div className={style.wrapper}>
      <h1>{title}</h1>
      <p>{desc}</p>

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        className={style.slider}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        loop
        modules={[Navigation]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
          },
        }}
      >
        {reviews.map((review, i) => {
          return (
            <SwiperSlide key={i + review.attributes.FullName}>
              <div className={style.swiper_slide}>
                <div className={style.user}>
                  <img
                    src={
                      BASE_URL + review.attributes.Profile.data.attributes.url
                    }
                    alt="User"
                  />
                  <div className={style.info}>
                    <h3>{review.attributes.FullName}</h3>
                    <p>{review.attributes.Profession}</p>
                  </div>
                </div>
                <p className={style.review}>{review.attributes.Review}</p>
              </div>
            </SwiperSlide>
          );
        })}

        <div ref={prevRef} className="custom-prev-button">
          {"<"}
        </div>
        <div ref={nextRef} className="custom-next-button">
          {">"}
        </div>
      </Swiper>
    </div>
  );
};

export default SixthBanner;
