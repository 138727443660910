import React from "react";
import { Helmet } from "react-helmet";

const withHelmet = (WrappedComponent, helmetProps) => {
  const HOC = (props) => (
    <>
      <Helmet>
        {helmetProps.title && <title>{helmetProps.title}</title>}
        {helmetProps.meta &&
          helmetProps.meta.map((metaProps, index) => (
            <meta key={index} {...metaProps} />
          ))}
        {helmetProps.link &&
          helmetProps.link.map((linkProps, index) => (
            <link key={index} {...linkProps} />
          ))}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );

  return HOC;
};

export default withHelmet;
