import React from "react";
import style from "./secondBanner.module.scss";
import { Link } from "react-router-dom";

const SecondBanner = ({ header, desc, image, image1 }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.side}>
        <h1>{header}</h1>
        <p>{desc}</p>
        <Link to="/about-us">About Us</Link>
        <div
          className={style.bgImage}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </div>
      <div
        className={style.side}
        style={{
          backgroundImage: `url(${image1})`,
        }}
      ></div>
    </div>
  );
};

export default SecondBanner;
