/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "./about.module.scss";
import Slider from "../../components/Shared/Slider/Slider";
import withHelmet from "../../seo/withHelmet";
import { aboutUsPageHelmetProps } from "../../seo/helmetProps";
import { BASE_URL, MainContext } from "../../store/main-context";
import Loader from "../../components/Shared/Loader/Loader";
const About = () => {
  const mainCtx = useContext(MainContext);
  useEffect(() => {
    mainCtx.getAboutUs();
    mainCtx.getProjects();
  }, []);

  console.log(!mainCtx.isEmptyObject(mainCtx.about) ? mainCtx.about : "");
  return (
    <>
      {mainCtx.isEmptyObject(mainCtx.about) ? (
        <Loader />
      ) : (
        <>
          <div className={style.wrapper}>
            <div
              className={style.banner}
              style={{
                backgroundImage: `url(${
                  BASE_URL +
                  mainCtx.about.data.data.attributes.Banner.data.attributes.url
                })`,
              }}
            />
            <div className={style.section}>
              <h1>{mainCtx.about.data.data.attributes.Block1.Title}</h1>
              <p>{mainCtx.about.data.data.attributes.Block1.Description}</p>
            </div>
          </div>
          <div className={style.section2}>
            <div className={style.side}>
              <h1>{mainCtx.about.data.data.attributes.Block2.Title}</h1>
              <p>{mainCtx.about.data.data.attributes.Block2.Description}</p>
            </div>
            <div className={style.side}>
              <h1>{mainCtx.about.data.data.attributes.Block3.Title}</h1>
              <p>{mainCtx.about.data.data.attributes.Block3.Description}</p>
            </div>
          </div>
          <div className={style.wrapper}>
            <Slider title={mainCtx.about.data.data.attributes.Projects.Title} />
          </div>
        </>
      )}
    </>
  );
};

export default withHelmet(About, aboutUsPageHelmetProps);
