export const homepageHelmetProps = {
  title: "Varli Construction ",
  meta: [
    {
      name: "description",
      content:
        "Discover Varli Construction, a leading construction company in Switzerland. With a commitment to quality and innovation, Varli Construction delivers exceptional building solutions that meet the highest standards of excellence.",
    },
    {
      name: "keywords",
      content:
        "Varli Construction, Construction, Building, Switzerland, Quality Construction, Innovative Building Solutions, Swiss Construction, Construction Company, Building Services, General Contractor, Construction Management, Architectural Design, Sustainable Building, Residential Construction, Commercial Construction, Infrastructure Projects, Construction Excellence, Swiss Builders, Building Innovation, Construction Expertise, Project Management, Construction Industry, Civil Engineering, Structural Engineering, Construction Projects",
    },
  ],
  link: [{ rel: "canonical", href: window.location.href }],
};

export const blogPageHelmetProps = {
  title: "Varli Construction Blog",
  meta: [
    {
      name: "description",
      content:
        "Welcome to the Varli Construction blog. Stay updated with the latest news, insights, and trends in the construction industry. Learn about innovative building solutions, project updates, and expert opinions from Varli Construction, a leading construction company in Switzerland.",
    },
    {
      name: "keywords",
      content:
        "Varli Construction Blog, Construction News, Construction Trends, Building Insights, Swiss Construction Industry, Innovative Building Solutions, Construction Updates, Expert Opinions, Quality Construction, Construction Projects, Sustainable Building, Residential Construction, Commercial Construction, Infrastructure Projects, Architectural Design, General Contractor, Construction Management, Swiss Builders, Building Innovation, Project Management, Construction Expertise, Civil Engineering, Structural Engineering",
    },
  ],
  link: [{ rel: "canonical", href: window.location.href }],
};

export const projectPageHelmetProps = {
  title: "Varli Construction Projects",
  meta: [
    {
      name: "description",
      content:
        "Discover the impressive projects by Varli Construction. Explore our portfolio of residential, commercial, and infrastructure projects, showcasing our commitment to quality, innovation, and sustainable building practices in Switzerland.",
    },
    {
      name: "keywords",
      content:
        "Varli Construction Projects, Residential Construction, Commercial Construction, Infrastructure Projects, Swiss Construction, Quality Construction, Sustainable Building, Innovative Building Solutions, Architectural Design, Project Management, Construction Expertise, Civil Engineering, Structural Engineering, General Contractor, Building Portfolio, Construction Showcase, Construction Developments, Building Excellence, Swiss Builders, Leading Construction Company, Construction Management, Completed Projects, Ongoing Projects, Construction Gallery",
    },
  ],
  link: [{ rel: "canonical", href: window.location.href }],
};
export const contactUsPageHelmetProps = {
  title: "Contact Varli Construction",
  meta: [
    {
      name: "description",
      content:
        "Get in touch with Varli Construction for all your building and construction needs. Reach out to us for inquiries, consultations, or to learn more about our services. We are here to assist you with your residential, commercial, and infrastructure projects in Switzerland.",
    },
    {
      name: "keywords",
      content:
        "Contact Varli Construction, Construction Inquiries, Consultation, Building Services, Residential Construction, Commercial Construction, Infrastructure Services, Swiss Construction Company, Contact Us, Customer Support, Construction Consultation, Building Questions, Project Inquiries, Construction Assistance, Construction Company Contact",
    },
  ],
  link: [{ rel: "canonical", href: window.location.href }],
};

export const aboutUsPageHelmetProps = {
  title: "About Varli Construction",
  meta: [
    {
      name: "description",
      content:
        "Learn more about Varli Construction, our mission, vision, and values. Discover our journey, expertise, and commitment to delivering high-quality construction projects. Explore our team's dedication to innovation, sustainability, and excellence in the construction industry in Switzerland.",
    },
    {
      name: "keywords",
      content:
        "About Varli Construction, Company Mission, Company Vision, Company Values, Construction Expertise, Construction Journey, Quality Construction, Sustainable Building, Innovative Construction, Swiss Construction Industry, Construction Excellence, Our Team, Construction Professionals, Building Expertise, Construction Innovation, Company Overview, Swiss Builders, Leading Construction Company, Construction Services, Company Background, Building Commitment",
    },
  ],
  link: [{ rel: "canonical", href: window.location.href }],
};
