import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Shared/Navbar";
import ScrollToTop from "./components/Shared/ScrollToTop";
import Footer from "./components/Shared/Footer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import About from "./pages/About";
import MainContextProvider from "./store/main-context";
// import Blog from "./pages/Blog";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
function App() {
  return (
    <Router>
      <MainContextProvider>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/projects/:id" element={<Project />} />
        </Routes>
        <Footer />
      </MainContextProvider>
    </Router>
  );
}

export default App;
