/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import FirstBanner from "../../components/home/FirstBanner/FirstBanner";
import SecondBanner from "../../components/home/SecondBanner/SecondBanner";
import ThirdBanner from "../../components/home/ThirdBanner/ThirdBanner";
import FourthBanner from "../../components/home/FourthBanner/FourthBanner";
import FifthBanner from "../../components/home/FifthBanner/FifthBanner";
import SixthBanner from "../../components/home/SixthBanner/SixthBanner";
// import SeventhBanner from "../../components/home/SeventhBanner/SeventhBanner";
import withHelmet from "../../seo/withHelmet";
import { homepageHelmetProps } from "../../seo/helmetProps";
import { BASE_URL, MainContext } from "../../store/main-context";
import Loader from "../../components/Shared/Loader/Loader";

const Home = () => {
  const mainCtx = useContext(MainContext);
  useEffect(() => {
    mainCtx.homepageData();
    mainCtx.getProjects();
    mainCtx.getReviews();
  }, []);

  console.log(!mainCtx.isEmptyObject(mainCtx.reviews) ? mainCtx.reviews : "");

  return !mainCtx.isEmptyObject(mainCtx.homepage) &&
    !mainCtx.isEmptyObject(mainCtx.projects) &&
    !mainCtx.isEmptyObject(mainCtx.reviews) ? (
    <>
      <FirstBanner
        banner={
          BASE_URL +
          mainCtx.homepage.data.data.attributes.FirstSection.Banner.data
            .attributes.url
        }
        description={
          mainCtx.homepage.data.data.attributes.FirstSection.Description
        }
        header={mainCtx.homepage.data.data.attributes.FirstSection.Header}
      />
      <SecondBanner
        desc={mainCtx.homepage.data.data.attributes.SecondSection.Description}
        header={mainCtx.homepage.data.data.attributes.SecondSection.Header}
        image={
          BASE_URL +
          mainCtx.homepage.data.data.attributes.SecondSection.Image1.data
            .attributes.url
        }
        image1={
          BASE_URL +
          mainCtx.homepage.data.data.attributes.SecondSection.Image2.data
            .attributes.url
        }
      />
      <ThirdBanner
        list={mainCtx.homepage.data.data.attributes.ThirdSection.List}
        counters={mainCtx.homepage.data.data.attributes.ThirdSection.Counters}
        title={mainCtx.homepage.data.data.attributes.ThirdSection.Title}
        desc1={mainCtx.homepage.data.data.attributes.ThirdSection.Description1}
        desc2={mainCtx.homepage.data.data.attributes.ThirdSection.Description2}
        desc3={mainCtx.homepage.data.data.attributes.ThirdSection.Description3}
      />
      <FourthBanner
        image={
          BASE_URL +
          mainCtx.homepage.data.data.attributes.FourthSection.Image.data
            .attributes.url
        }
        blocks={
          mainCtx.homepage.data.data.attributes.FourthSection.TitleDescription
        }
      />
      <FifthBanner
        projects={mainCtx.projects.data.data}
        title={mainCtx.homepage.data.data.attributes.ProjectsSectionTitle.Title}
      />
      <SixthBanner
        title={mainCtx.homepage.data.data.attributes.Reviews.Title}
        desc={mainCtx.homepage.data.data.attributes.Reviews.Description}
        reviews={mainCtx.reviews.data.data}
      />
      {/* <SeventhBanner /> */}
    </>
  ) : (
    <Loader />
  );
};

export default withHelmet(Home, homepageHelmetProps);
