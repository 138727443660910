/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "./project.module.scss";
import Slider from "../../components/Shared/Slider/Slider";
import { useParams } from "react-router-dom";
import { BASE_URL, MainContext } from "../../store/main-context";
import Loader from "../../components/Shared/Loader/Loader";
import ReactMarkdown from "react-markdown";
import withHelmet from "../../seo/withHelmet";
import { projectPageHelmetProps } from "../../seo/helmetProps";

const Project = () => {
  const mainCtx = useContext(MainContext);
  const { id } = useParams();

  console.log(id);

  useEffect(() => {
    mainCtx.getSingleProject(id);
  }, [id]);
  return (
    <>
      {mainCtx.isEmptyObject(mainCtx.singleProject) ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <div
            className={style.banner}
            style={{
              backgroundImage: `url(${
                BASE_URL +
                mainCtx.singleProject.data.data.attributes.Photo.data.attributes
                  .url
              })`,
            }}
          />

          <h1>{mainCtx.singleProject.data.data.attributes.Title}</h1>
          <ReactMarkdown>
            {mainCtx.singleProject.data.data.attributes.Description}
          </ReactMarkdown>
          {/* <p>{parse(mainCtx.singleProject.data.data.attributes.Description)}</p> */}

          <Slider title={"Other Projects"} />
        </div>
      )}
    </>
  );
};

export default withHelmet(Project, projectPageHelmetProps);
