import style from "./thirdBanner.module.scss";

const ThirdBanner = ({ title, desc1, desc2, desc3, list, counters }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.grid}>
        <div className={style.col}>
          <h1>{title}</h1>

          <ul>
            {list.slice(0, 3).map((listItem) => {
              return <li key={listItem.Title}>- {listItem.Title}</li>;
            })}
          </ul>
        </div>
        <div className={style.col}>
          <p>{desc1}</p>

          <ul>
            {list.slice(3, 6).map((listItem) => {
              return <li key={listItem.Title}>- {listItem.Title}</li>;
            })}
          </ul>
        </div>
        <div className={style.col}>
          <p>{desc2}</p>

          <p>{desc3}</p>
        </div>
      </div>
      <div className={style.grid}>
        {counters.map((counter) => {
          return (
            <div className={style.col} key={counter.Header}>
              <h1>+{counter.Count}</h1>
              <h3>{counter.Header}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThirdBanner;
