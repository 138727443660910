import axios from "axios";
import { createContext, useState } from "react";

export const BASE_URL = `https://api.varliconstruction.ch`;

const HOMEPAGE_URL = `/api/homepage?populate[FirstSection][populate]=*&populate[SecondSection][populate]=*&populate[ThirdSection][populate]=*&populate[FourthSection][populate]=*&populate[ProjectsSectionTitle][populate]=*&populate[Reviews][populate]=*`;
const PROJECTS_URL = `/api/projects?populate=*`;
const REVIEWS_URL = `/api/reviews?populate=*`;
const CONTACT_URL = `/api/contact?populate=*`;
const ABOUT_URL = `/api/about-us?populate=*`;
const PROJECTS_PAGE_URL = `/api/projects-page`;
const SINGLE_PROJECT_URL = `/api/projects/`;
export const MainContext = createContext({
  homepage: {},
  isEmptyObject: () => {},
  homepageData: () => {},
  projects: {},
  getProjects: () => {},
  reviews: {},
  getReviews: () => {},
  contact: {},
  getContact: () => {},
  about: {},
  getAboutUs: () => {},
  projectsPage: {},
  getProjectsPage: () => {},
  getProjectsWithPagination: () => {},
  singleProject: {},
  getSingleProject: () => {},
});

const MainContextProvider = ({ children }) => {
  const [homepage, setHomepage] = useState({});
  const [projects, setProjects] = useState({});
  const [reviews, setReviews] = useState({});
  const [contact, setContact] = useState({});
  const [about, setAbout] = useState({});
  const [projectsPage, setProjectsPage] = useState({});
  const [singleProject, setSingleProject] = useState({});
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  async function homepageData() {
    const results = await axios.get(`${BASE_URL}${HOMEPAGE_URL}`);

    setHomepage(results);
  }
  async function getProjects() {
    const results = await axios.get(`${BASE_URL}${PROJECTS_URL}`);

    setProjects(results);
  }

  async function getReviews() {
    const results = await axios.get(`${BASE_URL}${REVIEWS_URL}`);

    setReviews(results);
  }
  async function getContact() {
    const results = await axios.get(`${BASE_URL}${CONTACT_URL}`);

    setContact(results);
  }
  async function getAboutUs() {
    const results = await axios.get(`${BASE_URL}${ABOUT_URL}`);

    setAbout(results);
  }

  async function getProjectsPage() {
    const results = await axios.get(`${BASE_URL}${PROJECTS_PAGE_URL}`);

    setProjectsPage(results);
  }
  async function getProjectsWithPagination(page) {
    const results = await axios.get(
      `${BASE_URL}${PROJECTS_URL}&pagination[page]=${page}&pagination[pageSize]=10`
    );

    setProjects(results);
  }
  async function getSingleProject(id) {
    const results = await axios.get(
      `${BASE_URL}${SINGLE_PROJECT_URL}${id}?populate=*`
    );

    setSingleProject(results);
  }

  const value = {
    homepage: homepage,
    homepageData: homepageData,
    isEmptyObject: isEmptyObject,
    projects: projects,
    getProjects: getProjects,
    reviews: reviews,
    getReviews: getReviews,
    getContact: getContact,
    contact: contact,
    about: about,
    getAboutUs: getAboutUs,
    projectsPage: projectsPage,
    getProjectsPage: getProjectsPage,
    getProjectsWithPagination: getProjectsWithPagination,
    singleProject: singleProject,
    getSingleProject: getSingleProject,
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
export default MainContextProvider;
