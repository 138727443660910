/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useRef } from "react";

import style from "./slider.module.scss";

import Card from "../../projects/card/Card";
import { MainContext } from "../../../store/main-context";

const Slider = ({ title }) => {
  const prevRef1 = useRef(null);
  const nextRef1 = useRef(null);

  const mainCtx = useContext(MainContext);
  useEffect(() => {
    mainCtx.getProjects();
  }, []);

  console.log(!mainCtx.isEmptyObject(mainCtx.projects) ? mainCtx.projects : "");

  return (
    <>
      {!mainCtx.isEmptyObject(mainCtx.projects) ? (
        <>
          <h1>{title}</h1>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            className={style.slider}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef1.current;
              swiper.params.navigation.nextEl = nextRef1.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            navigation={{
              prevEl: prevRef1.current,
              nextEl: nextRef1.current,
            }}
            loop
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: true,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: true,
              },
            }}
          >
            {mainCtx.projects.data.data.map((project, i) => {
              return (
                <SwiperSlide key={project.attributes.Title + i}>
                  <Card
                    address={project.attributes.Address}
                    image={project.attributes.Photo.data.attributes.url}
                    link={`/projects/${project.id}`}
                  />
                </SwiperSlide>
              );
            })}

            <div ref={prevRef1} className="custom-prev-button1">
              {"<"}
            </div>
            <div ref={nextRef1} className="custom-next-button1">
              {">"}
            </div>
          </Swiper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Slider;
