import { Link } from "react-router-dom";
import style from "./fifthBanner.module.scss";
import ProjectsCard from "../../Shared/ProjectsCard/ProjectsCard";
import { getNewestProjects } from "../../../helper";
import { BASE_URL } from "../../../store/main-context";
const FifthBanner = ({ projects, title }) => {
  console.log(title);
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h1>{title}</h1>
        <Link to="/projects">View All ({projects.length})</Link>
      </div>
      <div className={style.grid}>
        {getNewestProjects(projects).map((project, i) => {
          return (
            <ProjectsCard
              key={project.attributes.Title}
              address={project.attributes.Address}
              description={project.attributes.Description}
              title={project.attributes.Title}
              year={project.attributes.Year}
              index={i + 1}
              type={project.attributes.Type}
              image={BASE_URL + project.attributes.Photo.data.attributes.url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FifthBanner;
