export function getNewestProjects(projects) {
  return projects
    .sort(
      (a, b) =>
        new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )
    .slice(0, 4);
}

export function formatDescription(description) {
  const maxLength = 87;
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + "...";
  }
  return description;
}
