import style from "./footer.module.scss";
import logo from "../../../assets/logoFooter.svg";
import arrow from "../../../assets/icons/arrowRigthGrey.png";
import tiktok from "../../../assets/icons/tiktok.svg";
import insta from "../../../assets/icons/instagram.svg";
import fb from "../../../assets/icons/facebook.svg";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.innerWrapper}>
          <Link to="/">
            <img src={logo} alt="Varli Construction" />
          </Link>
          <div className={style.subscription}>
            <h3>Subscribe to Our Newsletter</h3>
            <div className={style.input}>
              <input type="email" placeholder="Enter your email address" />
              <button>
                <img src={arrow} alt="Varli Construction" />
              </button>
            </div>
          </div>
        </div>
        <div className={style.innerWrapper}>
          <div className={style.links}>
            <Link to="/projects">Projects</Link>
            <Link to="/about-us">About Us</Link>
            {/* <Link to="/blog">Blog</Link> */}
          </div>
          <div className={style.links}>
            <a href="/" target="_blank">
              <img src={tiktok} alt="Varli Contruction Tiktok" />
            </a>
            <a href="/" target="_blank">
              <img src={insta} alt="Varli Contruction Instagram" />
            </a>
            <a href="/" target="_blank">
              <img src={fb} alt="Varli Contruction Facebook" />
            </a>
          </div>
        </div>
        <p>
          © {new Date().getFullYear()} made with love by{" "}
          <a href="pikabootech.com">Pikaboo Tech</a> - All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
