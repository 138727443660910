import style from "./loader.module.scss";
import loader from "../../../assets/excavator.gif";
import logo from "../../../assets/logoFooter.svg";
const Loader = () => {
  return (
    <div className={style.wrapper}>
      <img src={logo} alt="Varli Construction Logo" className={style.logo} />
      <img
        src={loader}
        alt="Varli Construction Loader"
        className={style.loader}
      />
    </div>
  );
};

export default Loader;
