/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "./contact.module.scss";
import logo from "../../assets/logoFooter.svg";
import { BASE_URL, MainContext } from "../../store/main-context";
import Loader from "../../components/Shared/Loader/Loader";
import parse from "html-react-parser";
import withHelmet from "../../seo/withHelmet";
import { contactUsPageHelmetProps } from "../../seo/helmetProps";

const Contact = () => {
  const mainCtx = useContext(MainContext);
  useEffect(() => {
    mainCtx.getContact();
  }, []);

  console.log(!mainCtx.isEmptyObject(mainCtx.contact) ? mainCtx.contact : "");
  return (
    <>
      {" "}
      {mainCtx.isEmptyObject(mainCtx.contact) ? (
        <Loader />
      ) : (
        <>
          <div
            className={style.innerWrapper}
            style={{
              backgroundImage: `url(${
                BASE_URL +
                mainCtx.contact.data.data.attributes.Banner.data.attributes.url
              })`,
            }}
          />
          <div className={style.wrapper}>
            <div className={style.contact}>
              <div className={style.side}>
                <img src={logo} alt="logo" />
                <h4>
                  Address of the offices,
                  {mainCtx.contact.data.data.attributes.Address}
                </h4>
                <h4>
                  Office number: {mainCtx.contact.data.data.attributes.Phone}
                </h4>
                <h4>
                  Email:{" "}
                  <a
                    href={
                      "mailTo:" + mainCtx.contact.data.data.attributes.Email
                    }
                  >
                    {mainCtx.contact.data.data.attributes.Email}
                  </a>
                </h4>
              </div>
              <div className={style.contactForm}>
                <div className={style.name}>
                  <input type="text" placeholder="Name" />
                  <input type="text" placeholder="Last Name" />
                </div>
                <input type="email" placeholder="Email" />
                <textarea placeholder="Message" />
                <button>Send</button>
              </div>
            </div>

            <div className={style.map}>
              <h1>Office in Maps</h1>
              {parse(mainCtx.contact.data.data.attributes.IframeOfLocation)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withHelmet(Contact, contactUsPageHelmetProps);
