import { formatDescription } from "../../../helper";
import style from "./projectsCard.module.scss";
const ProjectsCard = ({
  index,
  image,
  title,
  description,
  address,
  type,
  year,
}) => {
  return (
    <div className={style.card}>
      <div
        className={style.bgImage}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className={style.info}>
        <h1>{index}</h1>
        <div className={style.details}>
          <h1>{title}</h1>
          <p>{formatDescription(description)}</p>
        </div>
      </div>
      <div className={style.row}>
        <h3>{year}</h3>
        <h3>{address}</h3>
        <h3>{type}</h3>
      </div>
    </div>
  );
};

export default ProjectsCard;
